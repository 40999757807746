.landingPageOval {
    background-image: url("../../assets/images/Oval.png");
    height: 260px;
    width: 480px;
    position: absolute;
    right: 0;
    background-size: cover;
    opacity: 0.7;
    animation: float 6s ease-in-out infinite;
}

.landingPageContainer {
    height: 100vh;
    /* overflow: hidden; */
}

.landingPageMidSection {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(10%, -50%);
}

.sectionPadding {
    margin: 5rem 0;
}

.links-navigate {
    text-decoration: none;
    color: #fff;
}

.links-navigate:hover {
    text-decoration: underline;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 1060px) {
    .landingPageOval {
        height: 400px;
        width: 500px;
    }
}

@media (max-width: 740px) {
    .landingPageOval {
        height: 300px;
        width: 400px;
    }

    .contactUsButton {
        width: 200px;
    }
}

@media screen and (max-width: 650px) {
    .sourceBookService {
        font-size: 32px;
    }

    .yourLoadsWith {
        font-size: 32px;
    }

    .yourLoadsWith span {
        font-size: 32px;
        white-space: revert;
    }

    .content {
        font-size: 16px;
        line-height: 40px;
        white-space: inherit;
    }

    .landingPageMidSection {
        width: 80%;
    }
}

@keyframes float {
    0% {
        transform: translatey(0px);
    }

    50% {
        transform: translatey(-30px);
    }

    100% {
        transform: translatey(0px);
    }
}